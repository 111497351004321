body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

@media all and (min-width: 480px) {
  .Login {
    margin: 15% 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Login p {
    margin: 0 auto;
    padding-top: 20px;
    max-width: 320px;
    font-size: 0.8em;
    color: red;
  }

}

.Login {
  text-align: justify
}

.vl {
  border-left: 1px solid rgba(128, 128, 128, 0.5);
  display: inline;
}

.Monitor {
  margin-top: 10%;
  text-align: center;
}

.Loading {
  padding-top: 15%;
  text-align: center;
}

.Gathering {
  text-align: center;
  width: 100%;
  display: block;
}

.title {
  width: 100%;
}

.left {
  float: left;
  font-weight: 200;
}

.right {
  float: right;
  font-weight: 500;
}

.status {
  display: block
}

.report {
  font-weight: 550;
  font-size: 11pt;
  text-align: left
}

.bar {
  display: block;
}

.healthy {
  color: green;
}

.error {
  color: red;
}

.Report {
  margin-bottom: 50px;
}

.panel {
  margin-top: 10px;
}

.Manage {
  margin-top: 10%;
  width: 100%;
}

.textfield {
  margin-left: 1em;
}

.fullWidth {
  width: 100%;
}

.userTable {
  margin-bottom: 20px;
}

.panel {
  margin-top: 10px;
}

.NotFound {
  padding-top: 20%;
  text-align: center;
}

.App {
  margin-top: 100px;
  text-align: center;
}

.App .navbar-brand {
  font-weight: bold;
}

.logo {
  width: 80px;
  height: auto;
  margin-right: 5px
}

