.Monitor {
  margin-top: 10%;
  text-align: center;
}

.Loading {
  padding-top: 15%;
  text-align: center;
}

.Gathering {
  text-align: center;
  width: 100%;
  display: block;
}

.title {
  width: 100%;
}

.left {
  float: left;
  font-weight: 200;
}

.right {
  float: right;
  font-weight: 500;
}

.status {
  display: block
}

.report {
  font-weight: 550;
  font-size: 11pt;
  text-align: left
}

.bar {
  display: block;
}

.healthy {
  color: green;
}

.error {
  color: red;
}

.Report {
  margin-bottom: 50px;
}

.panel {
  margin-top: 10px;
}
