.Manage {
  margin-top: 10%;
  width: 100%;
}

.textfield {
  margin-left: 1em;
}

.fullWidth {
  width: 100%;
}

.userTable {
  margin-bottom: 20px;
}

.panel {
  margin-top: 10px;
}
