@media all and (min-width: 480px) {
  .Login {
    margin: 15% 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Login p {
    margin: 0 auto;
    padding-top: 20px;
    max-width: 320px;
    font-size: 0.8em;
    color: red;
  }

}

.Login {
  text-align: justify
}

.vl {
  border-left: 1px solid rgba(128, 128, 128, 0.5);
  display: inline;
}
