.App {
  margin-top: 100px;
  text-align: center;
}

.App .navbar-brand {
  font-weight: bold;
}

.logo {
  width: 80px;
  height: auto;
  margin-right: 5px
}
